import Vue from 'vue'
import Vuex from 'vuex'
//import * as d3 from "d3";
import 'firebase/firestore';
import * as fb from '../firebase'
import 'firebase/auth';
//import firebase from 'firebase/app';
//import { normalize, schema } from 'normalizr';



Vue.use(Vuex)

export default new Vuex.Store({
  
  state: {
    fields: [
     //\ { key: 'add_note', label: 'Add Note', thClass: '', tdClass: '', field_type: 'action', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'last_name', label: 'Last Name', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'first_name', label: 'First Name', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'student_name', label: 'Student Name', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      //{ key: 'pk', label: 'PK', thClass: 'd-none', tdClass: 'd-none', field_type: 'hidden', edit: false, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'student_id', label: 'Student ID', thClass: 'd-none', tdClass: 'd-none', sortable: true, field_type: 'student_fact', edit: false, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'show_grades', label:'Click for Grades', thClass: '', tdClass: '',stickyColumn: true},
      { key: 'last_one_to_one', label: 'Last 1 to 1 Check-in', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'check_ins', label: 'Check-ins', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'student_milestones', label: 'Student Milestone', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'last', label: 'Last Check-in', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
  
      //{ key: 'september_one_to_one', label: 'September One to One', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'otc', label: 'OTC', thClass: '', tdClass: '', field_type: 'action', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'otc_title_case', label: 'OTC', thClass: '', tdClass: '', field_type: 'action', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'ro_status', label: 'R/O Status', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'on_track_status', label: 'On Track Status', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'attendance', label: 'Attendance', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'n_passing_courses', label: '# Courses Passing', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'predicted_credits', label: 'Predicted Credits', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'suspensions', label: 'Suspensions', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'gender', label: 'Gender', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'ell_status', label: 'ELL Status', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'sped_status', label: 'SpEd Status', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'ethnicity', label: 'Ethnicity', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'age', label: 'Age', thClass: '', tdClass: '', sortable: true, field_type: 'student_fact', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      
      { key: 'student_email', label: 'Student Email', thClass: 'd-none', tdClass: 'd-none', sortable: true, field_type: 'student_fact', edit: false, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'district', label: 'District', thClass: 'd-none', tdClass: 'd-none', field_type: 'institution', edit: false, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'school', label: 'School', thClass: '', tdClass: '', field_type: 'institution', edit: false, filterOptions:[],selectedFilterOptions:[],filtered:false},
      
      { key: 'track_september_1_1', label: 'September 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_classroom_observation', label: 'September Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_google_classroom', label: 'September Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_group_workshop', label: 'September Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_home_visit', label: 'September Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_letter_home', label: 'September Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_parent_communication', label: 'September Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_parent_meeting', label: 'September Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_ppt_504', label: 'September PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_school_staff', label: 'September School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_september_student_direct', label: 'September Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'september', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'track_october_1_1', label: 'October 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_classroom_observation', label: 'October Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_google_classroom', label: 'October Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_group_workshop', label: 'October Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_home_visit', label: 'October Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_letter_home', label: 'October Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_parent_communication', label: 'October Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_parent_meeting', label: 'October Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_ppt_504', label: 'October PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_school_staff', label: 'October School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_october_student_direct', label: 'October Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'october', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'track_november_1_1', label: 'November 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_classroom_observation', label: 'November Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_google_classroom', label: 'November Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_group_workshop', label: 'November Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_home_visit', label: 'November Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_letter_home', label: 'November Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_parent_communication', label: 'November Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_parent_meeting', label: 'November Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_ppt_504', label: 'November PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_school_staff', label: 'November School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_november_student_direct', label: 'November Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'november', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
 
      { key: 'track_december_1_1', label: 'December 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_classroom_observation', label: 'December Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_google_classroom', label: 'December Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_group_workshop', label: 'December Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_home_visit', label: 'December Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_letter_home', label: 'December Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_parent_communication', label: 'December Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_parent_meeting', label: 'December Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_ppt_504', label: 'December PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_school_staff', label: 'December School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_december_student_direct', label: 'December Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'track_january_1_1', label: 'January 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_classroom_observation', label: 'January Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_google_classroom', label: 'January Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_group_workshop', label: 'January Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_home_visit', label: 'January Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_letter_home', label: 'January Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_parent_communication', label: 'January Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_parent_meeting', label: 'January Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_ppt_504', label: 'January PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_school_staff', label: 'January School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_january_student_direct', label: 'January Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'track_february_1_1', label: 'February 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_classroom_observation', label: 'February Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_google_classroom', label: 'February Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_group_workshop', label: 'February Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_home_visit', label: 'February Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_letter_home', label: 'February Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_parent_communication', label: 'February Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_parent_meeting', label: 'February Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_ppt_504', label: 'February PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_school_staff', label: 'February School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_february_student_direct', label: 'February Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'track_march_1_1', label: 'March 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_classroom_observation', label: 'March Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_google_classroom', label: 'March Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_group_workshop', label: 'March Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_home_visit', label: 'March Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_letter_home', label: 'March Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_parent_communication', label: 'March Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_parent_meeting', label: 'March Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_ppt_504', label: 'March PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_school_staff', label: 'March School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_march_student_direct', label: 'March Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'track_april_1_1', label: 'April 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_classroom_observation', label: 'April Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_google_classroom', label: 'April Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_group_workshop', label: 'April Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_home_visit', label: 'April Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_letter_home', label: 'April Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_parent_communication', label: 'April Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_parent_meeting', label: 'April Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_ppt_504', label: 'April PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_school_staff', label: 'April School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_april_student_direct', label: 'April Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'track_may_1_1', label: 'May 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_classroom_observation', label: 'May Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_google_classroom', label: 'May Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_group_workshop', label: 'May Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_home_visit', label: 'May Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_letter_home', label: 'May Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_parent_communication', label: 'May Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_parent_meeting', label: 'May Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_ppt_504', label: 'May PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_school_staff', label: 'May School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_may_student_direct', label: 'May Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'track_june_1_1', label: 'June 1 to 1', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_classroom_observation', label: 'June Classroom Observation', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_google_classroom', label: 'June Google Classroom', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_group_workshop', label: 'June Group/Workshop', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_home_visit', label: 'June Home Visit', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_letter_home', label: 'June Letter Home', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_parent_communication', label: 'June Parent Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_parent_meeting', label: 'June Parent Meeting', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_ppt_504', label: 'June PPT/504', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_school_staff', label: 'June School Staff Communication', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'track_june_student_direct', label: 'June Student Direct', thClass: '', tdClass: '', sortable: true, field_type: 'type_count', edit: false, stickyColumn: true, filterOptions:[],selectedFilterOptions:[],filtered:false},

      { key: 'flags', label: 'Flags', thClass: 'd-none', tdClass: 'd-none', field_type: 'information', edit: false, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'total_notes', label: 'Notes', thClass: '', tdClass: '', field_type: 'action', edit: false, filterOptions:[],selectedFilterOptions:[],filtered:false},
      { key: 'tags', label: 'Tags', thClass: '', tdClass: '', field_type: 'information', edit: true, filterOptions:[],selectedFilterOptions:[],filtered:false},
  ],
  field_groups: [
    { field_type: 'hidden', title: 'Hidden Fields',makecard: 'false'},
    { field_type: 'student_fact', title: 'Student Facts',makecard: 'true'},
    { field_type: 'type_count', title: 'Type Count',makecard: 'true'},
    { field_type: 'september', title: 'September',makecard: 'true'},
    { field_type: 'october', title: 'October',makecard: 'true'},
    { field_type: 'november', title: 'November',makecard: 'true'},
    //{ field_type: 'step_tracking', title: 'Step Completion',makecard: 'true'},
    //{ field_type: 'college_process', title: 'College Process',makecard: 'true'},
    //{ field_type: 'academic', title: 'Academic',makecard: 'true'},
    { field_type: 'information', title: 'Information',makecard: 'true'},
    { field_type: 'action', title: 'Actions',makecard: 'false'},


  ],

    button_data: [
      {
          label: 'Student Profile Stats'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
          //'last_name',
          //'first_name',
          'student_name',
          'show_grades',
          'last_one_to_one',
          //'student_milestones',
          'otc_title_case',
          'on_track_status',
          'attendance',
          'n_passing_courses',
          'predicted_credits',
          'suspensions',
          'gender',
          'ell_status',
          'sped_status',
          'ethnicity',
          'age',
          'add_note',
          ]
        },
        {
          label: 'Student Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'august_one_to_one'
            , 'august_student_direct'
            ,

          ]

        },

        {
          label: 'September Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_september_1_1'
            , 'track_september_student_direct'
            //, 'track_september_classroom_observation'
            //, 'track_september_google_classroom'
            //, 'track_september_group_workshop'
            , 'track_september_parent_communication'
            , 'track_september_parent_meeting'
            , 'track_september_home_visit'
            , 'track_september_letter_home'
            //, 'track_september_ppt_504'
            //, 'track_september_school_staff'
            

          ]

        },


        {
          label: 'October Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_october_1_1'
            , 'track_october_student_direct'
            //, 'track_october_classroom_observation'
            //, 'track_october_google_classroom'
            //, 'track_october_group_workshop'
            , 'track_october_parent_communication'
            , 'track_october_parent_meeting'
            , 'track_october_home_visit'
            , 'track_october_letter_home'
            //, 'track_october_ppt_504'
            //, 'track_october_school_staff'
            

          ]

        },

        {
          label: 'November Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_november_1_1'
            , 'track_november_student_direct'
            //, 'track_november_classroom_observation'
            //, 'track_november_google_classroom'
            //, 'track_november_group_workshop'
            , 'track_november_parent_communication'
            , 'track_november_parent_meeting'
            , 'track_november_home_visit'
            , 'track_november_letter_home'
            //, 'track_november_ppt_504'
            //, 'track_november_school_staff'
            

          ]

        },

        
        {
          label: 'December Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_december_1_1'
            , 'track_december_student_direct'
            //, 'track_december_classroom_observation'
            //, 'track_december_google_classroom'
            //, 'track_december_group_workshop'
            , 'track_december_parent_communication'
            , 'track_december_parent_meeting'
            , 'track_december_home_visit'
            , 'track_december_letter_home'
            //, 'track_december_ppt_504'
            //, 'track_december_school_staff'  
          ]
        },

        {
          label: 'January Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_january_1_1'
            , 'track_january_student_direct'
            //, 'track_january_classroom_observation'
            //, 'track_january_google_classroom'
            //, 'track_january_group_workshop'
            , 'track_january_parent_communication'
            , 'track_january_parent_meeting'
            , 'track_january_home_visit'
            , 'track_january_letter_home'
            //, 'track_january_ppt_504'
            //, 'track_january_school_staff'  
          ]
        },

        {
          label: 'February Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_february_1_1'
            , 'track_february_student_direct'
            //, 'track_february_classroom_observation'
            //, 'track_february_google_classroom'
            //, 'track_february_group_workshop'
            , 'track_february_parent_communication'
            , 'track_february_parent_meeting'
            , 'track_february_home_visit'
            , 'track_february_letter_home'
            //, 'track_february_ppt_504'
            //, 'track_february_school_staff'  
          ]
        },

        {
          label: 'March Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_march_1_1'
            , 'track_march_student_direct'
            //, 'track_march_classroom_observation'
            //, 'track_march_google_classroom'
            //, 'track_march_group_workshop'
            , 'track_march_parent_communication'
            , 'track_march_parent_meeting'
            , 'track_march_home_visit'
            , 'track_march_letter_home'
            //, 'track_march_ppt_504'
            //, 'track_march_school_staff'  
          ]
        },

        {
          label: 'April Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_april_1_1'
            , 'track_april_student_direct'
            //, 'track_april_classroom_observation'
            //, 'track_april_google_classroom'
            //, 'track_april_group_workshop'
            , 'track_april_parent_communication'
            , 'track_april_parent_meeting'
            , 'track_april_home_visit'
            , 'track_april_letter_home'
            //, 'track_april_ppt_504'
            //, 'track_april_school_staff'  
          ]
        },

        {
          label: 'May Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_may_1_1'
            , 'track_may_student_direct'
            //, 'track_may_classroom_observation'
            //, 'track_may_google_classroom'
            //, 'track_may_group_workshop'
            , 'track_may_parent_communication'
            , 'track_may_parent_meeting'
            , 'track_may_home_visit'
            , 'track_may_letter_home'
            //, 'track_may_ppt_504'
            //, 'track_may_school_staff'  
          ]
        },

        {
          label: 'June Milestones'
          , graph_config: {
          xaxis: 'On-Track Status'
          , yaxis: 'Students'
          , aggregate_on: 'on_track_status'
          }
          , fields: [
            'student_name'
            , 'on_track_status'
            , 'last_one_to_one'
            , 'track_june_1_1'
            , 'track_june_student_direct'
            //, 'track_june_classroom_observation'
            //, 'track_june_google_classroom'
            //, 'track_june_group_workshop'
            , 'track_june_parent_communication'
            , 'track_june_parent_meeting'
            , 'track_june_home_visit'
            , 'track_june_letter_home'
            //, 'track_june_ppt_504'
            //, 'track_june_school_staff'  
          ]
        },





      ],
    student_data: []
    , isBusy: true
    , uid: "" 

  },
  mutations: {

    setUser(state, val) {
      state.uid = val
      console.log(val)
    },

    setStudents(state, val) {
      console.log('start setStudents')
      // console.log(val)
      state.student_data = val
      state.isBusy = false
    }

    , appendStudentNote(state, payload) {
      console.log(state)
      console.log(payload)

      const student = payload.student_row;
      const noteText = payload.noteText;
      const noteCategory = payload.noteCategory;
      const user = payload.user;

      state.deep_students[student]["notes"].push({
        note_date: new Date()
        , note_text: noteText
        , note_type: noteCategory
        , note_entered_by: user
      })
    }

  },
  actions: {


    get_collection(district, otc_user) {
      otc_user = fb.auth.currentUser
      console.log(otc_user)
      fb.db.collection('otc_caseload_collection').doc(district).collection(otc_user)
    },

    async setStudentData(state, doc) { 
      //console.log(doc)

      // This will filter out students dropped from caseloads after a specified number of days
      let recent_dates = [...Array(5)].map((_, i) => {
        let d = new Date()
        d.setDate(d.getDate() - i)
        return d.toISOString().split('T')[0]
      })

      let otc_auth_id = doc['otc_auth_id']
      //console.log(otc_auth_id)
      let role = doc['roles']

      let firestore_source = 'multiDistrictStudents'

      let studentsCollection = fb[firestore_source].where('otc_auth_id','==',otc_auth_id)

     studentsCollection.onSnapshot(snapshot => {
        console.log("start snapshot")
      let studentsArray =[]

      if (role == 'rise_central') {

        snapshot.forEach(doc =>{
          let student = doc.data()
          student.id = doc.id

          studentsArray.push(student)
  
        })
        
      } else {

        snapshot.forEach(doc =>{
          let student = doc.data()
          student.id = doc.id
  
          if (recent_dates.indexOf( student.last_rise_update ) !== -1) {
            //console.log(student.last_rise_update)
  
            studentsArray.push(student)
            
          }
  
        })
        
      }
    
    console.log(studentsArray.length)
      state.commit('setStudents', studentsArray)
    })
  },

    async setUserData(state, uid) {
      state.commit('setUser', uid)

  },

//######################### for new auth security rules
  
  async getUserSchools(state, uid) {
    state.dispatch("setUserData", uid);
   
    fb.userDb.doc(uid).get().then((doc) => {
      if (doc.exists) {
         console.log(doc.data()['schools'])  
         console.log(doc.data()) 
         console.log(doc.data()['email']) 

      //run setStudentData to load in data from the appropriate schools
       state.dispatch("setStudentData", doc.data());
       
       
      // state.commit("setUser", doc.data()['email'])
      // state.commit("setUserSchools", doc.data()['schools'])

       
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
  }).catch((error) => {
      console.log("Error getting document:", error);
  });
  }

  // firestoreNewNote(student, payload){}

  },
  modules: {
  },
  getters: {

    preppedTableData: 
    //[...state.student_data].sort((a, b) => a.last_name - b.last_name)
    
    state => 
    state.student_data.map(
      student=>({
        otc_title_case: student.otc_title_case
        , doc_id: student.document_id
        , district: student.district
        , student_id: student.student_id
        , last_name: student.last_name
        , first_name: student.first_name
        , student_name: student.last_name + ', ' + student.first_name
        , student_email: student.student_email
        , ethnicity: student.ethnicity
        , gender: student.gender
        , age: student.age
        , on_track_status: student.on_track_status
        , attendance: student.attendance
        , suspensions: student.suspensions
        , ro_status: student.ro_status
        , n_passing_courses: student.n_passing_courses
        , predicted_credits: student.predicted_credits
        , ell_status: student.ell_status
        , sped_status: student.sped_status
        , last_one_to_one: student.last_one_to_one
 

        , check_ins: student.check_ins
        , last_check_in: student.last

        , track_september_1_1: student.track_september_1_1.length
        , track_september_classroom_observation: student.track_september_classroom_observation.length
        , track_september_google_classroom: student.track_september_google_classroom.length
        , track_september_group_workshop: student.track_september_group_workshop.length
        , track_september_home_visit: student.track_september_home_visit.length
        , track_september_letter_home: student.track_september_letter_home.length
        , track_september_parent_communication: student.track_september_parent_communication.length
        , track_september_parent_meeting: student.track_september_parent_meeting.length
        , track_september_ppt_504: student.track_september_ppt_504.length
        , track_september_school_staff: student.track_september_school_staff.length
        , track_september_student_direct: student.track_september_student_direct.length

        , track_october_1_1: student.track_october_1_1.length
        , track_october_classroom_observation: student.track_october_classroom_observation.length
        , track_october_google_classroom: student.track_october_google_classroom.length
        , track_october_group_workshop: student.track_october_group_workshop.length
        , track_october_home_visit: student.track_october_home_visit.length
        , track_october_letter_home: student.track_october_letter_home.length
        , track_october_parent_communication: student.track_october_parent_communication.length
        , track_october_parent_meeting: student.track_october_parent_meeting.length
        , track_october_ppt_504: student.track_october_ppt_504.length
        , track_october_school_staff: student.track_october_school_staff.length
        , track_october_student_direct: student.track_october_student_direct.length

        , track_november_1_1: student.track_november_1_1.length
        , track_november_classroom_observation: student.track_november_classroom_observation.length
        , track_november_google_classroom: student.track_november_google_classroom.length
        , track_november_group_workshop: student.track_november_group_workshop.length
        , track_november_home_visit: student.track_november_home_visit.length
        , track_november_letter_home: student.track_november_letter_home.length
        , track_november_parent_communication: student.track_november_parent_communication.length
        , track_november_parent_meeting: student.track_november_parent_meeting.length
        , track_november_ppt_504: student.track_november_ppt_504.length
        , track_november_school_staff: student.track_november_school_staff.length
        , track_november_student_direct: student.track_november_student_direct.length

        , track_december_1_1: student.track_december_1_1.length
        , track_december_classroom_observation: student.track_december_classroom_observation.length
        , track_december_google_classroom: student.track_december_google_classroom.length
        , track_december_group_workshop: student.track_december_group_workshop.length
        , track_december_home_visit: student.track_december_home_visit.length
        , track_december_letter_home: student.track_december_letter_home.length
        , track_december_parent_communication: student.track_december_parent_communication.length
        , track_december_parent_meeting: student.track_december_parent_meeting.length
        , track_december_ppt_504: student.track_december_ppt_504.length
        , track_december_school_staff: student.track_december_school_staff.length
        , track_december_student_direct: student.track_december_student_direct.length

        , track_january_1_1: student.track_january_1_1.length
        , track_january_classroom_observation: student.track_january_classroom_observation.length
        , track_january_google_classroom: student.track_january_google_classroom.length
        , track_january_group_workshop: student.track_january_group_workshop.length
        , track_january_home_visit: student.track_january_home_visit.length
        , track_january_letter_home: student.track_january_letter_home.length
        , track_january_parent_communication: student.track_january_parent_communication.length
        , track_january_parent_meeting: student.track_january_parent_meeting.length
        , track_january_ppt_504: student.track_january_ppt_504.length
        , track_january_school_staff: student.track_january_school_staff.length
        , track_january_student_direct: student.track_january_student_direct.length

        , track_february_1_1: student.track_february_1_1.length
        , track_february_classroom_observation: student.track_february_classroom_observation.length
        , track_february_google_classroom: student.track_february_google_classroom.length
        , track_february_group_workshop: student.track_february_group_workshop.length
        , track_february_home_visit: student.track_february_home_visit.length
        , track_february_letter_home: student.track_february_letter_home.length
        , track_february_parent_communication: student.track_february_parent_communication.length
        , track_february_parent_meeting: student.track_february_parent_meeting.length
        , track_february_ppt_504: student.track_february_ppt_504.length
        , track_february_school_staff: student.track_february_school_staff.length
        , track_february_student_direct: student.track_february_student_direct.length

        , track_march_1_1: student.track_march_1_1.length
        , track_march_classroom_observation: student.track_march_classroom_observation.length
        , track_march_google_classroom: student.track_march_google_classroom.length
        , track_march_group_workshop: student.track_march_group_workshop.length
        , track_march_home_visit: student.track_march_home_visit.length
        , track_march_letter_home: student.track_march_letter_home.length
        , track_march_parent_communication: student.track_march_parent_communication.length
        , track_march_parent_meeting: student.track_march_parent_meeting.length
        , track_march_ppt_504: student.track_march_ppt_504.length
        , track_march_school_staff: student.track_march_school_staff.length
        , track_march_student_direct: student.track_march_student_direct.length

        , track_april_1_1: student.track_april_1_1.length
        , track_april_classroom_observation: student.track_april_classroom_observation.length
        , track_april_google_classroom: student.track_april_google_classroom.length
        , track_april_group_workshop: student.track_april_group_workshop.length
        , track_april_home_visit: student.track_april_home_visit.length
        , track_april_letter_home: student.track_april_letter_home.length
        , track_april_parent_communication: student.track_april_parent_communication.length
        , track_april_parent_meeting: student.track_april_parent_meeting.length
        , track_april_ppt_504: student.track_april_ppt_504.length
        , track_april_school_staff: student.track_april_school_staff.length
        , track_april_student_direct: student.track_april_student_direct.length

        , track_may_1_1: student.track_may_1_1.length
        , track_may_classroom_observation: student.track_may_classroom_observation.length
        , track_may_google_classroom: student.track_may_google_classroom.length
        , track_may_group_workshop: student.track_may_group_workshop.length
        , track_may_home_visit: student.track_may_home_visit.length
        , track_may_letter_home: student.track_may_letter_home.length
        , track_may_parent_communication: student.track_may_parent_communication.length
        , track_may_parent_meeting: student.track_may_parent_meeting.length
        , track_may_ppt_504: student.track_may_ppt_504.length
        , track_may_school_staff: student.track_may_school_staff.length
        , track_may_student_direct: student.track_may_student_direct.length

        , track_june_1_1: student.track_june_1_1.length
        , track_june_classroom_observation: student.track_june_classroom_observation.length
        , track_june_google_classroom: student.track_june_google_classroom.length
        , track_june_group_workshop: student.track_june_group_workshop.length
        , track_june_home_visit: student.track_june_home_visit.length
        , track_june_letter_home: student.track_june_letter_home.length
        , track_june_parent_communication: student.track_june_parent_communication.length
        , track_june_parent_meeting: student.track_june_parent_meeting.length
        , track_june_ppt_504: student.track_june_ppt_504.length
        , track_june_school_staff: student.track_june_school_staff.length
        , track_june_student_direct: student.track_june_student_direct.length

        , notes: student.notes
        , tags: student.tags
      //  ]).filter(Boolean).length
      })
    ),

    caseload: (state) => {
      var caseload = state.student_data.map( student => {
          return {
            student_name: student.last_name + ', ' + student.first_name,
            student_id: student.student_id,
            document_id: student.document_id,
            district: student.district,
            otc: student.otc
           // last_overall_check_in: student.last_overall_check_in,
          };
      });
      return caseload;
    },

    // this retrieves an array of objects with each students' last check-in
    last: (state) => {
          var last = state.student_data.map( student => {
              return {
                last_check_in: student.last
              };
          });
          return last;
    },

    auth: (state) => {
      var auth = state.student_data.map( student => {
        return {
          uid: state.uid,
          district: student.district,
          otc: student.otc
        };
    });

      return auth;
    },


    check_in_history: (state) => {
    var check_in_history = state.student_data.map( student => {
      //this.$store.state.student_data.filter(x=>x.document_id==doc_id)[0].check_ins
      
        return {
          student_name: student.last_name + ', ' + student.first_name,
          //student_id: student.student_id,
          document_id: student.document_id,
          on_track_status: student.on_track_status,
          district: student.district,
          otc: student.otc
          
        };
    });
    return check_in_history;
  },

  lastCheckins: (state) => {
    var lastCheckins = state.student_data.map( student => {
      //this.$store.state.student_data.filter(x=>x.document_id==doc_id)[0].check_ins
      
        return {
          studentName:student.student_name,
          lastOverall: student.last,
          lastOneToOne: student.last_one_to_one,
          onTrackStatus: student.on_track_status,
          otc: student.otc_title_case
          
        };
    });
    return lastCheckins;
  },

  
  downloadAll: (state) => {
    var downloadAll = state.student_data.map( student => {
      
        return {
          checkin: student.check_ins,
          otcStudent: student.otc_title_case + ' - ' + student.last_name + ', ' + student.first_name,
          otc: student.otc_title_case
          
        };
    });
    return downloadAll;
  },


    gradesTableData:
    state =>
    state.student_data[0].courses.map(
      course=>({
        //marking_period: course.storecode
        name: course.course_name
        , Y1: course.grade
        //, Q1: course.grade
      })
    )
      
  },
})




