import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import Tracker from '../views/Tracker.vue'
import Login from '../views/Login.vue'
import NewCheckin from '../views/NewCheckin.vue'
import CheckinHistory from '../views/CheckinHistory.vue'
import CheckinTracker from '../views/CheckinTracker.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { hideNavigation: true }
  },

  {
    path: '/tracker',
    name: 'Caseload Tracker',
    
  
    component: Tracker
  },

  {
    path: '/check-in',
    name: 'New Checkin',
    
  
    component: NewCheckin
  },

  {
    path: '/check-in-history',
    name: 'Checkin History',
    
  
    component: CheckinHistory
  },

  {
    path: '/last-check-in',
    name: 'Last Check-in',
    
  
    component: CheckinTracker
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
