<template>
      
    <b-container @contextmenu.prevent > 
    <div v-if="$store.state.isBusy">
          <b-button variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>
            Loading...
        </b-button>
        <b-row class="p-2">
            <b-skeleton-img
            width="100%"
            height="50px"
            no-aspect
            > 
            </b-skeleton-img>
        </b-row>
        <b-row class="p-2">
            <b-skeleton-table
                :rows="20"
                :columns="8"
                :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>
        </b-row>
    </div> 
    <div id="everything" v-if="!$store.state.isBusy">
        <!--<button @click="$router.push('/check-in')" type="button" class="btn ">Check-in</button>-->
    <!--<button @click="firestore_get_data">
      Get Firestore Data
    </button>

    <button @click="firestore_put_data">
      add to the tag field
    </button>-->


    <b-row id="graph">
        <b-col>
                <bar-graph
                v-bind:graph_data="aggStepComplete"
                v-bind:graph_labels="selectedStepGraphLabels"
                
                >

                </bar-graph>
        </b-col>
    </b-row>

    <b-row id="controls" align-v="center">

    <b-col id="pagination">
        <b-pagination
            v-model="pagination.currentPage"
            :total-rows="rows"
            :per-page="pagination.perPage"
                    class="m-0"
           
            ></b-pagination>
    </b-col>

    <b-col>
        <b-form-select
            id="per-page-select"
            v-model="pagination.perPage"
            :options="pagination.pageOptions"
            
          ></b-form-select>
    </b-col>

    <!--<b-col>
        <b-button 
        :variant="filteredFields.length > 0 ? 'danger' : 'secondary'"
        :disabled="filteredFields.length > 0 ? false : true"
        @click="clearAllFilters"
        >
        <b-icon icon="x-square-fill"></b-icon>
            Clear All Filters
        </b-button>
    </b-col>-->



        <b-col id="column-control-2">
            <b-form-select
                v-model="column_group_selected"
                :options="column_group_options"
            
                @change="visibleFieldsControl(column_group_selected)"
            >
            </b-form-select>
        </b-col>
        
        <!--<b-col>
            <b-button v-b-toggle.collapse-1 variant="primary">Customize Visible Columns</b-button>
        </b-col>-->



    </b-row>



<b-row align-h="center" id="collapsable-row-for-field-hiding"
                    class="m-2"
>
  <b-collapse id="collapse-1" class="mt-2">
 <b-row id="checkboxes" align-h="center">
     <b-col>
 <b-card-group>

     <b-card
     border-variant="dark"
     v-for="cgroup in customizableFieldGroups" 
     :key="cgroup.field_type"
     :header="cgroup.title"
     align="center"
     >
     <b-card-body align="left" class="p-0">
        <b-checkbox
            v-for="field in field_by_type(cgroup.field_type)" 
            :key="field.key"
            value=""
            unchecked-value="d-none"
            v-model="field.thClass"
            @change="checkColumn([field.key, $event])"
            class="table-condensed"
            >

            {{ field.label }}

        </b-checkbox>
     </b-card-body>

     </b-card>

 </b-card-group>
  </b-col>
    </b-row>
  </b-collapse>
   
 

</b-row>


<!-- BEGIN TABLE  -->

    <!-- contextmenu.prevent in order to make right clicking work to open filter menu -->
        <b-row id="table"
        @contextmenu.prevent  
        >
            <b-table 
            sticky-header="100%"
            head-variant="light"
            responsive
            hover
            small
            :fields="tableFields"
            :items="liveDeepStudents" 
            :per-page="pagination.perPage"
            :current-page="pagination.currentPage"
            :filter="popFilter.filter_table"
            class="table-condensed"
            borderless
            style="min-width:0"
            striped
            sort-by='last_name'
            >

<!---  default field format ---
        <template #cell()="row" >
            <div  @mousedown.right="r_click_handler(row.item, row.field.key, row.index, $event)"
            :id="(row.field.key).concat('_').concat(row.index)"
            v-b-popover.hover.top="
            'Hello!'
            "
            >
           
         {{ row.value }}
           
            </div>

     
        </template>-->
<!---  END default field format --->

<!-- BEGIN tag field format -->

<template #cell(tags)="row"


>

<b-badge href="#" variant="success">
{{ row.value }}
</b-badge>


</template>

<!-- END tag field format -->


<template #cell(show_grades)="student_row"
  >
        <div 
              :id="(student_row.field.key).concat('_').concat(student_row.index)"
              @click="setGradesStudent(student_row.item)"

            >
        
            <b-button 
            
            >Grades</b-button>
        </div>

        </template>

            </b-table>
        </b-row>


<!-- BEGIN POPOVER  (for filtering)-->

<b-popover 

    :target="this.popFilter.popTarget" 
    :show="this.popFilter.showPop" 
    placement="right"
    @contextmenu.prevent 
    >
    
    <!-- <template #title  @contextmenu.prevent >
        <b-button @click="onFilterClose" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Filter: {{popFilter.filterTitle}} </template> -->

    <div id="popover_inputs" 
    
    @contextmenu.prevent
    
    > 
        <b-form-group
            label-for="filter-drop-down"
            description="Hold control to select more than one option"
            class="mb-1"
            
        >
        <b-form-select
            id="filter-drop-down"
            multiple
            v-model="popFilter.selectedFilterOptions"
            :options="popFilter.filterOptions"
        >       
        </b-form-select>
        </b-form-group>

        <b-button @click="onFilterOk" size="sm" class="m-1" variant="primary">Filter</b-button>
        <b-button @click="onFilterClear" size="sm" class="m-1" variant="danger">Clear Filter</b-button>
        <b-button @click="onFilterClose" size="sm" class="m-1" variant="secondary">Cancel</b-button>

    </div>
    
  
  
  
  
  
  
  </b-popover>

  <!-- END POPOVER (for filtering)-->

  <!-- BEGIN POPOVER  (for adding note)-->

<b-popover 

    :target="this.addNoteProperties.popTarget" 
    :show="this.addNoteProperties.showPop" 
    placement="right"
    @contextmenu.prevent 
    >
    
    <!-- <template #title  @contextmenu.prevent >
        <b-button @click="onFilterClose" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Filter: {{popFilter.filterTitle}} </template> -->

    <div id="note_popover_inputs" 
    
    @contextmenu.prevent
    
    > 


        <div>

        </div>

        <!--<b-button @click="onNoteSubmit(addNoteProperties.noteStudentTarget, addNoteProperties.newNoteText, addNoteProperties.newNoteCategory, currentUser)" size="sm" class="m-1" variant="primary">Submit Note</b-button>
        <b-button @click="onNoteCancel" size="sm" class="m-1" variant="secondary">Cancel</b-button>-->

    </div>
    
  
  
  
  
  
  
  </b-popover>

  <!-- END POPOVER (for adding note)-->
  <!-- START MODAL -->
    <b-modal id="grades-modal"
        :title="this.studentGradesProperties.targetStudentName"
    >
   
        <gradesTable :student_doc_id="this.studentGradesProperties.targetStudent">
            </gradesTable>

    </b-modal>
  <!-- END MODAL -->
    </div>
    </b-container>
    
</template>

<style scoped>

.editable-column-true {
    font-weight: bold;
    

}

.editable-column-false {
    font-weight: bold
}

.toggle-header {
    text-decoration: underline overline;
}

.table-condensed{
  font-size: 13px;
}

.control-row {
    /* font-size: 13px; */
    margin: 0;
}

/* .scrollable-dropdown {
    max-height: 300px;
    overflow-y: auto;
  } */

  .scrollable-menu {
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.not-too-wide {
    
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}



.tooltip {
    text-transform: capitalize;
}

.editable-column-true {
    font-weight: bold;
    

}

.editable-column-false {
    font-weight: bold
}

.toggle-header {
    font-weight: bold;
}

</style>

<script>
import * as d3 from "d3";
import barGraph from '../components/barGraph.vue'
import * as fb from '../firebase'
// import db from '../firebase.js'
import firebase from 'firebase/app';
import gradesTable from '../components/gradesTable.vue'




export default {

 components: { 
     //formModal
  barGraph
 
  , gradesTable
 //, 'download-csv': JsonCSV 
 },

        data(){
    return{
        deepStudents: []
       , tableFields: []
        , fieldGroups: []
        , buttonData: []
        , isBusy: false
        , currentUser: fb.auth.currentUser
        , firestore_data: []
    


    //used for interactive fields that can be edited by users
        , edit_row: ''
        , edit_field: ''
        , doc_id: ''
    ///////////////////////////////////////////////////////
    //used for changing which fields are in view
        , column_group_selected: ''
        , column_group_options: [
                {value: null, text: 'Select an option', disabled: true}
                , {value: 'Student Profile Stats', text: 'Academic Performance'}
                //, {value: 'Student Profile Stats', text: 'Check-in Milestones'}
                //, {value: 'Student Profile Stats', text: 'Alerts'}
                , {
                    label: 'Check-in Milestone Trackers',
                    options: [
                        {value: 'September Milestones', text: 'September'}
                        , {value: 'October Milestones', text: 'October'}
                        , {value: 'November Milestones', text: 'November'}
                        , {value: 'December Milestones', text: 'December'}
                        , {value: 'January Milestones', text: 'January'}
                        , {value: 'February Milestones', text: 'February'}
                        , {value: 'March Milestones', text: 'March'}
                        , {value: 'April Milestones', text: 'April'}
                        , {value: 'May Milestones', text: 'May'}
                        , {value: 'June Milestones', text: 'June'}
                    ]
                }
            ]
        , pickedFields: []
        , selectedStep: '' 
        , selectedStepGraph: []
        , selectedStepGraphLabels: {}
    //////////////////////////////////////////////////////
    //table pagination 
        , pagination:
                {
                    perPage: 15,
                    currentPage: 1,
                    pageOptions: [10, 15, 30, { value: 99900, text: "Show All" }],
                }
    /////////////////////////////////////////////////////////

       , popFilter: {filterTitle: 'placeholder title',
                    filterField: '',
                    filterOptions: [{ text: '- Choose 1 -', value: '' }, 'Red', 'Green', 'Blue'],
                    selectedFilterOptions: [],
                    clearButtonDisabled:true,
                    clearButtonVariant: 'secondary',
                    popTarget: '',
                    showPop: false,
                    filter_table: '',
                    }
    ///////////////////////////////////////////////////////////
        , studentGradesProperties: {
            targetStudent: '',
            targetStudentName: '',
        }
    /////////////////////////////////////////////////
        , addNoteProperties: {
            showPop: false,
            noteStudentTarget: '',
            popTarget: '',
            newNoteText: '',
            newNoteCategory: null,
            newNoteCategoryOptions: [
                {text: 'Please select an option', value: null, disabled: true}
                , {text: 'General', value: 'general'}
                , {text: 'Financial Aid', value: 'financial_aid'}
                , {text: 'Applications', value: 'applications'}
                , {text: 'Graduation Status', value: 'graduation_status'}
                , {text: 'Recommendations', value: 'recommendations'}
            ],
            textState: null,
            categoryState: null,
        }
    }
    },
    async created() {
        // this.deepStudents = this.$store.getters.preppedTableData;
        // this.tableFields = this.$store.state.fields
        // this.fieldGroups = this.$store.state.field_groups
        // this.buttonData  = this.$store.state.button_data
        // this.load_data().then(() => {
        //         console.log('yippie')
        //         //this.visibleFieldsControl(this.buttonData.filter(x=>x.label=='Academics')[0])
        // })
        //this.$store.dispatch("setStudentData")

       await this.load_data()
       console.log("tracker.vue created")
    //    this.load_data()
       
        
        
    },
    /*async mounted(){
        console.log("tracker.vue mounted")
        // this.load_data()
        
    },
    beforeMount(){
        // this.load_data()
    },*/
    computed: {
        grades_pivot() {
           
        //let student = this.student_doc_id;
        let student = 'ct_merryville_60e86e7cfc13ae524a000017';
         let data = this.$store.state.student_data.filter(x=>x.document_id==student)[0].courses
            
        let columns = [...new Set(data.map(d=>d.storecode))]
        let classes = data.map(c=> ({course_name: c.course_name, teacher: c.teacher, period: c.period}))
    
        for (let c in classes) {
            for (let m in columns) {
            let grade = data.filter(x=> x['storecode']==columns[m] && x['course_name']==classes[c].course_name)[0]
            classes[c][columns[m]]=grade
            }
        }

          
            return classes
        },
        
        
        studentDataContainer(){
            return this.$store.state.student_data
        },

        liveDeepStudents(){
            
            var x = this.$store.getters.preppedTableData;

            // var x = this.deepStudents

        

            var i
                let f = this.filteredFields;
                if(f.length>0) {
                    for (i in f) {
                let filter_set = this.tableFields.filter(x=>x.key==f[i])[0].selectedFilterOptions
                
                x = x.filter(k=>filter_set.includes(k[f[i]]))
                    }
                
                }
                return x

                },


        //this is the list of field groups that will show up in the visible column drawer
        customizableFieldGroups(){
        var x = this.fieldGroups.filter(k=>k.makecard=='true')

        return x
        },


        //tells the pagination controls the maximum number of rows in the table
        rows() {
        return this.liveDeepStudents.length
        },

        ///////////////////////////////////////////
        //used for graphs
        aggStepComplete() { // aggregate the step_completed field for the selected step

        const stepName = this.selectedStep
        const x = d3.rollup(this.liveDeepStudents, v=>v.length, d=>d[stepName])
        
        const y = Array.from(x, ([a,count]) => ({a,count}))

        let z = y.map(
            ({ a, count }) => ({ [stepName]: a, students: count })
        )
        z = z.sort((a,b) => {
         let fa = a[stepName],
             fb = b[stepName];
                if (fa < fb) {return -1;}
                if (fa > fb) {return 1;}
                return 0;
            }
        )
        return z
        },
        ////////////////////////////////////////////

        //////////////////////////////////////////////
        //used for filtering
         filteredFields(){
        let x = this.tableFields.filter(k=>k.filtered)

        x = x.map(k=>k.key)

        return x
        },
        /////////////////////////////////////////////

    },
    methods: {
        say: function (msg) {
            console.log(msg)
            },
        
       async load_data() {
        //    this.isBusy = true;
           console.log("begin load data")
           try {
               this.tableFields = await this.$store.state.fields;
           } catch {
               console.log('fields load failed')
           } 

           try {
               this.fieldGroups = await this.$store.state.field_groups;
           } catch {
               console.log('field_groups load failed')
           }

           try {
                this.buttonData  = await this.$store.state.button_data;
           } catch {
               console.log('button_data failed')
           }
  
           try {
                this.visibleFieldsControl("Student Profile Stats")
                this.column_group_selected="Student Profile Stats"
    //this.deepStudents = this.$store.state.student_data;

           } catch {
               console.log('visibleFieldsControl failed')
           } 

        },

        async firestore_get_data() {
            // await this.$store.dispatch("setStudentData");
            // this.firestore_data = await this.$store.state.student_data;
            // console.log(this.firestore_data)
           
        //    fb.oneStudent.get()
        //             .then((doc) => console.log(doc.data()))

            fb.studentsCollection.where('student_id', '==', '113197810')
                        .get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) =>{
                                console.log(doc.ref.id)
                            })
                        })
            

        },

        firestore_put_data() {
            // await this.$store.dispatch("setStudentData");
            // this.firestore_data = await this.$store.state.student_data;
            // console.log(this.firestore_data)
            fb.oneStudent.update({
                tags: firebase.firestore.FieldValue.arrayUnion({
                    'date_updated': new Date(),
                    'active': true,
                    'tag': 'wowie!'
                })

            })
                        

        },
////////////////////////////////////////////////////
        // //used for interactive fields in the table
        // set_edit_row: function(row) {
        //         return this.edit_row = row;
        //     },

        // set_edit_field: function(field) {
        //         return this.edit_field = field;
        //     },

        // set_doc_id: function(id) {
        //         return this.doc_id = id;
        // },

        // push_status_update: function(status, student, field) {
        //       this.say(status + ' ' + field + ' ' + student + ' ' + new Date())
        //     //   let row = this.edit_row;
        //     //   let f = this.edit_field

        //      this.$store.commit("firestoreAppendStatusUpdate", {status, student, field})
        //     },
////////////////////////////////////////////////////

/////////////////////////////////////////////////////
        // //used for setting up for loops
        // toggle_field_list: function(ftype) {
        //     return this.tableFields.filter(x => x.edit_type == ftype)
        // },

        
//////////////////////////////////////////////////////

/////////////////////////////////////////////////////
        // used for controlling which fields are on screen
        visibleFieldsControl: function (option) {
            console.log('Menu triggered for:' + option)
            let f = {}
            f = this.buttonData.filter(k=> k.label==option)[0]
            this.pickedFields = f.fields;
            this.selectedStep = f.graph_config.aggregate_on;
            this.selectedStepGraphLabels = f.graph_config;
            this.tableFields.forEach(x => this.pickedFields.includes(x.key) ?
                (x.tdClass='', x.thClass='') : (x.tdClass='d-none', x.thClass='d-none')
                
                )

            console.log(f)

        },

        checkColumn: function(arg1) {
            let n = '';
            n = this.tableFields.filter(x=>x.key==arg1[0])
            n[0].tdClass = n[0].thClass;
        },

        field_by_type: function(ftype) {
         return this.tableFields.filter(x => x.field_type==ftype)

        },

        /////////////////////////////////////////

        /////////////////////////////////////////

        onFilterOk() {
            if(this.popFilter.selectedFilterOptions.length>0){
            let chosenField=this.popFilter.filterField
            //this.say(this.popFilter.selectedFilterOptions)


            this.tableFields.filter(k=>k.key==chosenField)[0].selectedFilterOptions=this.popFilter.selectedFilterOptions
            this.tableFields.filter(k=>k.key==chosenField)[0].filtered=true;
            }
            //this.say(this.fields.filter(k=>k.key==chosenField)[0].selectedFilterOptions)
            this.popFilter.showPop =!this.popFilter.showPop
        },

        onFilterClear() {
            let chosenField=this.popFilter.filterField
            this.tableFields.filter(k=>k.key==chosenField)[0].selectedFilterOptions=[];
            this.tableFields.filter(k=>k.key==chosenField)[0].filtered=false;
            this.popFilter.showPop =!this.popFilter.showPop
        },

        onFilterClose() {
            this.popFilter.showPop =!this.popFilter.showPop
        },

        clearAllFilters() {
                this.tableFields.filter(k=>k.filtered).forEach(f=>f.filtered=false)
            },

         r_click_handler: function(item, field_key, index, event) {
                    //do stuff
                    //e.preventDefault();
                    let filterKey = field_key + '_' + index

                    this.popFilter.popTarget = filterKey

                    //load v-model from fields table if this filter has already been set
                    this.popFilter.selectedFilterOptions=this.tableFields.filter(k=>k.key==field_key)[0].selectedFilterOptions
                    
                   
                    this.popFilter.filterTitle = this.tableFields.filter(k=>k.key==field_key)[0].label
                   //look in original data set and computed data set to get the longest set of options
                        let original_set = this.liveDeepStudents.map(k=>k[field_key]).filter((v,i,a) => a.indexOf(v)===i).sort()
                        let computed_set = this.liveDeepStudents.map(k=>k[field_key]).filter((v,i,a) => a.indexOf(v)===i).sort()

                    if(original_set[0]==undefined) {
                        this.popFilter.filterOptions = computed_set
                    } else {
                        this.popFilter.filterOptions = original_set
                    }
                   // this.popFilter.filterOptions = this.deep_students.map(k=>k[field_key]).filter((v,i,a) => a.indexOf(v)===i).sort()
                    this.popFilter.filterField = field_key


                    console.log(item)
                    console.log(field_key)
                    console.log(index)
                    console.log(event)

                    // console.log(original_set[0]==undefined)
                    // console.log(computed_set[0])

                    this.say(this.popFilter.popTarget)
                   this.$nextTick(()=> 
                   this.popFilter.showPop = !this.popFilter.showPop
                   )
                   //this.$root.$emit('bv::show::popover', this.popTarget)
                    //event.preventDefault()

     },
     ////////////////////////////////////
     //Grades table methods

     setGradesStudent(payload){
         this.studentGradesProperties.targetStudent=payload.doc_id
         this.studentGradesProperties.targetStudentName=payload.student_name
         
             this.$root.$emit('bv::show::modal', 'grades-modal')
         

     },
     ////////////////////////////////////////////////////////
     //Add Note Methods



    }
}
</script>