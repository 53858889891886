<template>
    
    <div id="chart">
    <svg >

    </svg>
    </div>
</template>

<style>
.bar{
  fill: #785c9c;
}

.topbar{
  fill: lightgrey;
}
</style>

<script>
import * as d3 from "d3";
// import moment from 'moment'


export default {
    props: {
        graph_data: Array,
        graph_labels: Object,
        width: {
            default: 800,
            type: Number,
        },
         height: {
            default: 200,
            type: Number,
        },
        margin: {
            default: 60,
            type: Number,
        },
    },
data() {
        return {
            
  
      step_complete: [
        {"date":"2021-04-02 20:16:15","completed":51},
        {"date":"2021-04-03 10:44:15","completed":77},
        {"date":"2021-04-04 02:16:58","completed":18},
        {"date":"2021-04-05 08:27:43","completed":26},
        {"date":"2021-04-06 05:00:00","completed":58},
        {"date":"2021-04-07 01:23:33","completed":50},
        {"date":"2021-04-08 02:17:58","completed":76}
        ],

        chart: null,
        
        graph_config: {
            key: 'first_gen',
            value: 'count'
        }
        
        ,


        };
        

    },

    mounted() {
  
this.renderChart()
  },

watch: {
  graph_data: function(){
    this.renderChart()
  }
},

computed: {

    the_data(){
    //   return this.step_complete.map(x=>
    //   ({date: moment(x.date).format("MM.DD.YYYY"), completed: x.completed}) )
      
      return this.graph_data.map(x=> 
      ({date: x.key, completed: x.value}))
      
      
      
   
    },

        viewBox(){
        return `0 0 this.width this.height`
    },

},



     methods: {

renderChart() {

const key = this.graph_labels.aggregate_on;
const value = 'students';
const xaxis_label = this.graph_labels.xaxis;
// const yaxis_label = this.graph_labels.yaxis;

 const margin = 60;
  const svg_width = this.width;
  const svg_height = this.height;
  const chart_width = svg_width - 2 * margin;
  const chart_height = svg_height - 2 * margin;
  const maxScaleY = d3.max(this.graph_data.map(x=>x[value]));

  const svg = d3
    .select("#chart")
    .select("svg")
    .attr("width", svg_width)
    .attr("height", svg_height);

  svg.selectAll("g").remove()
  svg.selectAll("text").remove()

 this.chart = svg
    .append("g")
    .attr("transform", `translate(${margin}, ${margin})`);



const yScale = d3.scaleLinear()
  .range([chart_height, 0])
  .domain([0, maxScaleY+10])

this.chart
    .append("g")
  // .call(d3.axisLeft().scale(yScale).ticks(maxScaleY/10));

 // text label for the y axis
  // svg.append("text")
  //     .attr("transform", "rotate(-90)")
  //     .attr("y", 0 )
  //     .attr("x",0 - (svg_height / 2))
  //     .attr("dy", "1em")
  //     .style("text-anchor", "middle")
  //     .text(yaxis_label)
  //     .enter(); 


const xScale = d3
  .scaleBand()
  .range([0, chart_width])
  .domain(this.graph_data.map(x=>x[key]))
  .padding(0.2)

 this.chart
    .append("g")
  .attr("transform", `translate(0, ${chart_height})`)
  .call(d3.axisBottom(xScale) 
  );

  // text label for the x axis
  svg.append("text")             
      .attr("transform",
            "translate(" + (svg_width/2) + " ," + 
                           (chart_height + margin + 40) + ")")
      .style("text-anchor", "middle")
      .text(xaxis_label);


const barGroups = this.chart
  .selectAll('rect')
  .data(this.graph_data)
  .enter()

 barGroups
  .append('rect')
  .attr('class', 'bar')
  .attr('x', (g) => xScale(g[key]))
  .attr('y', (g) => yScale(g[value]))
  .attr('height', (g) => chart_height - yScale(g[value]))
  .attr('width', xScale.bandwidth())
   

 

  barGroups
  .append('text')
  .text(function(d) {
    // return d[value] + "-" + i
    return d[value] + " students"
  })
  .attr("x", function(d) {
    return xScale(d[key]) + xScale.bandwidth()/2;
  })
  .attr("y", function(d) {
    return yScale(d[value]) - 5;
  })
      .attr("font-family" , "sans-serif")
    .attr("font-size" , "14px")
    .attr("fill" , "black")
    .attr("text-anchor", "middle");


 


}
}


}

</script>