<template>
  <b-container>
    <b-row class="my-5">
      <b-col sm="3 offset-1" class="left-col">
        <div id="selection-text" align="left">
          <p>Select a student to view their check-in history.</p>
        </div>
        <div id="search-box">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="side_filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>

        <div>
          <div>
            <b-table
              sticky-header="400px"
              :items="this.check_in_history"
              :fields="caseload_fields"
              :filter="side_filter"
              :select-mode="selectMode"
              sort-by="student_name"
              responsive="sm"
              ref="selectableTable"
              selectable
              @row-selected="onRowSelected"
              @filtered="onFilteredStudents"
            >
              <!-- Example scoped slot for select state illustrative purposes -->
              <template #cell(selected)="{ rowSelected }" \>
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
            <!--<p>
                Selected Rows:<br>
                {{ selected }}
            </p>-->
          </div>
        </div>

        <div id="download-section">
          <div id="selection-text" align="left">
            <p>Download check-in history.</p>
          </div>
          <b-button block variant="warning">
            <b-icon icon="cloud-download-fill"> </b-icon>

            <download-csv
              
              :data="this.downloadAll"
              :fields="this.exportFields"
            >
            </download-csv
            >
          </b-button>
        </div>
      </b-col>

      <b-col sm="7">
        <div id="profile-card">
          <!--<p>{{ this.students }}</p>-->

          <b-card
            class="mt-3"
            align="left"
            border-variant="dark"
            bg-variant="dark"
            text-variant="white"
            v-for="(i, key) in this.students"
            :key="key.id"
          >
            <b-card-text>
              <b>Student:</b> {{ i.student_name || "" }}
            </b-card-text>
            <b-card-text>
              <b>Status:</b> {{ i.on_track_status || "" }}
            </b-card-text>
          </b-card>
        </div>

        <b-container fluid>
          <!-- User Interface controls -->
          <b-row>
            <b-col md="12" class="my-1">
              <b-form-group
                label="Filter on Notes/Type"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col sm="5" md="6" class="my-1">
              <b-form-group
                label="Per page"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="left"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>

          <!-- Main table element -->
          <b-table
            :items="this.v"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            thead-class="green-bg bg-primary text-white"
            small
            @filtered="onFiltered"
          >
            <template #cell(name)="row">
              {{ row.value.first }} {{ row.value.last }}
            </template>

            <template #cell(actions)="row">
              <b-button
                size="sm"
                @click="info(row.item, row.index, $event.target)"
                class="mr-1 action"
              >
                Edit
              </b-button>
              <b-button
                class="mr-1 action"
                size="sm"
                @click="openDeleteModal(row.item, row.index, $event.target)"
              >
                Delete
              </b-button>
            </template>

            <template #row-details="row">
              <b-card v-for="(i, key) in row.item" :key="key.id">
                <ul>
                  <li v-for="(value, key) in i" :key="key">
                    {{ key }}: {{ value }}
                  </li>
                  <!--<li v-for="note in row.item.all_notes" :key="note.notes">{{ key }}: {{ value }}</li>-->
                </ul>
              </b-card>
            </template>

          </b-table>

          <!-- Info modal -->
          <b-modal
            :id="infoModal.id"
            :title="infoModal.title"
            hide-footer
            @hide="resetInfoModal"
            size="md"
          >
            <!--<pre>{{ infoModal.content }}</pre>-->
            <b-card-text>
              <b>Select new options to update this check-in.</b>
            </b-card-text>
            <div class="col-sm-9 offset-3">
              <p>{{ infoModal.content.check_in_type }}</p>
            </div>

            <b-form-group id="input-group-type">
              <label
                for="input-group-type"
                class="col-sm-3 col-form-label"
                label-cols-sm="2"
                >Type:</label
              >
              <b-form-select
                class="col-sm-9"
                id="checkin-type"
                :value="infoModal.content.check_in_type"
                v-model="infoModal.content.check_in_type"
                :selected="infoModal.content.check_in_type"
                :options="checkinType"
                required
              ></b-form-select>
            </b-form-group>

            <div class="col-sm-9 offset-3">
              <p>{{ infoModal.content.youth_development }}</p>
            </div>

            <b-form-group id="input-group-ydf">
              <label for="input-group-ydf" class="col-sm-3 col-form-label"
                >YDF:</label
              >
              <b-form-select
                class="col-sm-9"
                id="youth-development"
                v-model="infoModal.content.youth_development"
                :value="infoModal.content.youth_development"
                :selected="infoModal.content.youth_development"
                :options="youthDevelopment"
                required
              ></b-form-select>
            </b-form-group>

            <div class="col-sm-9 offset-3">
              <p>{{ infoModal.content.check_in_length }}</p>
            </div>

            <b-form-group id="input-group-length">
              <label for="input-group-length" class="col-sm-3 col-form-label"
                >Length:</label
              >
              <b-form-select
                class="col-sm-9"
                id="checkin-length"
                v-model="infoModal.content.check_in_length"
                :value="infoModal.content.check_in_type"
                :selected="infoModal.content.check_in_type"
                :options="checkinLength"
                required
              >
              </b-form-select>
            </b-form-group>

            <b-form-group id="input-group-5">
              <b-form-textarea
                id="notes"
                v-model="infoModal.content.notes"
                placeholder="Enter check-in notes..."
                rows="3"
                max-rows="8"
              ></b-form-textarea>
            </b-form-group>

            <b-button
              variant="primary"
              @click="this.updateCheckin"
              class="btn btn-primary float-right"
              >Update</b-button
            >
            <!--<b-button variant="primary" class="btn btn-primary float-right">Update</b-button>-->
          </b-modal>

          <!-- Delete confirmation modal -->
          <b-modal
            :id="deleteModal.id"
            :title="deleteModal.title"
            hide-footer
            @hide="resetDeleteModal"
            size="md"
          >
            <div id="confirm-delete">
              <b-card-text>
                <b>Are you sure you want to delete this check-in?</b>
              </b-card-text>

              <b-card
                class="mt-3"
                border-variant="light"
                bg-variant="default"
                text-variant="default"
              >
                <!--<b-card-sub-title><b>Last Check-in:</b> </b-card-sub-title>-->

                <b-card-text>
                  <b>Date/time:</b> {{ deleteModal.content.check_in_date }}
                </b-card-text>
                <b-card-text>
                  <b>Type/duration:</b> {{ deleteModal.content.check_in_type }},
                  {{ deleteModal.content.check_in_length }}
                </b-card-text>
                <b-card-text>
                  <b>YDF:</b> {{ deleteModal.content.youth_development }}
                </b-card-text>
                <b-card-text>
                  <b>Note:</b> {{ deleteModal.content.notes }}
                </b-card-text>
              </b-card>
            </div>
            <!--<pre>{{ deleteModal.content }}</pre>--

        <input type="button" @click="this.deleteCheckIn" class="btn btn-danger float-right" variant="danger" value="Delete">-->
            <b-button
              variant="danger"
              @click="this.deleteCheckIn"
              class="btn btn-info float-right"
              >Delete</b-button
            >
          </b-modal>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as fb from "../firebase";
import firebase from "firebase/app";
import JsonCSV from "vue-json-csv";

export default {
  components: {
    "download-csv": JsonCSV,
  },
  data() {
    return {

      fields: [
        //{key: 'check_in_date', label: 'Date', sortable: true, sortDirection: 'desc', sort_priority: 0},
        {
          key: "display_date",
          label: "Date",
          sortable: true,
          sortDirection: "desc",
          sort_priority: 0,
        },
        //{key: 'student_name', label: 'Name', sortable: false, sortDirection: 'desc', sort_priority: 0},
        {
          key: "check_in_type",
          label: "Type",
          sortable: true,
          sort_priority: 0,
        },
        {
          key: "youth_development",
          label: "YDF",
          sortable: true,
          sort_priority: 0,
        },
        { key: "notes", label: "Notes", thStyle: { width: "300px" } },
        { key: "actions", label: "Actions" },
      ],

      exportFields: ["otc", "check_in_type", "youth_development",	"district",	"student_name",	"check_in_date",	
                "check_in_id","is_impromptu",	"check_in_length", "notes"],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "Date",
      sortDesc: false,
      sortDirection: "desc",
      side_filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      deleteModal: {
        id: "delete-modal",
        title: "",
        content: "",
      },
      copiedModalContent: {},
      v: [],
      form: {
        isImpromptu: "",
        name: "",
        checkinType: null,
        checkinLength: null,
        students: "",
        date: "",
        notes: "",
        selected: [],
      },
      youthDevelopment: [
        { text: "Select One", value: null },
        { text: "Positive Relationships", value: "Positive Relationships" },
        { text: "Self-Advocacy", value: "Self-Advocacy" },
        { text: "Self-Regulation", value: "Self-Regulation" },
        { text: "Self-Motivation", value: "Self-Motivation" },
        { text: "N/A", value: "N/A" },
      ],
      checkinType: [
        { text: "Select One", value: null },
        "1/1 Student Meeting",
        "Student Direct Message",
        "Google Classroom/Talking Points",
        "Parent Meeting",
        "Parent Communication",
        "Home Visit",
        "Letter Home",
        "School Staff Communication",
        "PPT/504 Meeting",
        "Classroom Observation/Push-In",
        "Group/Workshop/Lunch Bunch",
      ],

      checkinLength: [
        { text: "Select One", value: null },
        "0-5 mins.",
        "5-10 mins.",
        "10-15 mins.",
        "15-20 mins.",
        "20-30 mins.",
        "30-45 mins.",
        "45-60 mins",
        "60+ mins.",
        "attempted",
      ],
      show: true,

      // text/keyword filter
      filter: "",

      modes: [
        //'multi',
        "single",
        //'range'
      ],

      caseload_fields: ["student_name"],

      selectMode: "single",
      selected: [],
      students: [],
    };
  },

  watch: {
    globalQuery() {
      // Force refreshing the data table.
      this.$root.$emit("bv::refresh::table", this.v);
    },
  },

  computed: {
    caseload() {
      return this.$store.getters.caseload;
    },

    check_in_history() {
      return this.$store.getters.check_in_history;
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },

    downloadAll() {

        var data = this.$store.getters.downloadAll

        let all_otc_checkins = []

        data.forEach(element => {
          let i = element.checkin

          i.forEach(element => {
            all_otc_checkins.push(element)
            
          });

        });
        
        //console.log(all_otc_checkins)
        return all_otc_checkins
      },
  },

  mounted() {
    // Set the initial number of items
    //this.totalRows = this.v.length // not using this anymore
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      //alert(JSON.stringify(this.form))
    },

    onRowSelected(items) {
      this.selected = items;
      //this.form.selected = items
      //this.students = items
      this.student_doc_id = items;

      this.selected.forEach((element) => {
        //console.log(element.document_id)

        let doc_id = element.document_id;
        //console.log(doc_id)

        var x = this.checkins_source(doc_id);
        this.v = x;

        this.students = items;

        // set totalRows value to enable pagination
        this.totalRows = x.length;
      });
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
      this.form.selected = [];
      this.students = "";
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.testFn();
      //this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2);
    },

    onFilteredStudents(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    testFn() {
      console.log(typeof this.selected);
      alert(this.check_in_history);
    },

    checkins_source(doc_id) {
      //let student = doc_id;
      //console.log(student)
      let data = this.$store.state.student_data.filter(
        (x) => x.document_id == doc_id
      )[0].check_ins;
      let checkins = data.map((c) => ({
        student_name: c.student_name,
        check_in_id: c.check_in_id,
        check_in_type: c.check_in_type,
        check_in_length: c.check_in_length,
        youth_development: c.youth_development,
        is_impromptu: c.is_impromptu,
        student_id: c.student_id,
        timestamp: c.timestamp,
        display_date:
          new Date(c.timestamp).toLocaleDateString("en-US", {
            timeZone: "UTC",
          }) +
          " " +
          new Date(c.timestamp).toLocaleTimeString(),
        sort_timestamp: new Date(c.timestamp).toLocaleString("en-US", {
          timeZone: "UTC",
        }),
        check_in_date: c.check_in_date,
        notes: c.notes,
        document_id: doc_id,
        tracker_key_ref: c.tracker_key_ref,
        district: c.district,
        otc: c.otc,
      }));

      //alert(typeof checkins)

      var sorted = checkins.sort(function (a, b) {
        var c = new Date(a.sort_timestamp).getTime();
        var d = new Date(b.sort_timestamp).getTime();
        return d - c;
      });

      return sorted;

      //return checkins
    },

    onEdit(item) {
      if (item.isEditing) item.isEditing = false;
      else this.$set(item, "isEditing", true);
      //alert(item)
    },

    info(item, index, button) {
      //this.infoModal.title = `Row index: ${index}`
      this.infoModal.title = item.student_name;
      //this.infoModal.content = JSON.stringify(item, null, 2)
      this.infoModal.content = item;
      this.copiedModalContent = JSON.parse(JSON.stringify(item));
      //alert(this.copiedModalContent.check_in_type)
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    openDeleteModal(item, index, button) {
      //this.deleteModal.title = `Row index: ${index}`
      this.deleteModal.title = item.student_name;
      this.deleteModal.content = item;
      this.$root.$emit("bv::show::modal", this.deleteModal.id, button);
    },

    hideUpdateModal() {
      this.$root.$emit("bv::hide::modal", this.infoModal.id);
    },

    hideDeleteModal() {
      this.$root.$emit("bv::hide::modal", this.deleteModal.id);
    },

    resetDeleteModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    deleteConfirmation(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      //this.infoModal.content = JSON.stringify(item, null, 2)
      this.infoModal.content = item;

      //alert(this.copiedModalContent.check_in_type)
      this.$root.$emit("bv::show::modal", this.deleteModal.id, button);
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = this.v.length;
      this.currentPage = 1;
    },

    updateCheckin() {
      //alert(this.infoModal.content)
      //alert(this.copiedModalContent.check_in_type)
      console.log(this.copiedModalContent);
      let tracker_key_ref = this.copiedModalContent.tracker_key_ref;
      //alert('tracker_key_ref: ' + tracker_key_ref)

      // build tracker_key for use in the monthly trackers, reference to it for the primary check_ins array
      //let month = this.currentMonth().toLowerCase()
      let d = new Date(this.infoModal.content.timestamp);
      console.log(d);
      let month = d.toLocaleString("en-us", { month: "long" }).toLowerCase();
      console.log(month);

      let type = this.infoModal.content.check_in_type
        .replace(/\//g, "_")
        .toLowerCase();
      let type2 = type.replace(" ", "_");
      let typeFinal = type2.split("_", 2)[0] + "_" + type2.split("_", 2)[1];
      let typeFinal2 = typeFinal.split(" ")[0];
      var new_tracker_key = "track_" + month + "_" + typeFinal2;

      fb.otcStudents
        .doc(this.infoModal.content.district)
        .collection("prod_otcs")
        .doc(this.infoModal.content.otc)
        .collection("prod_caseload")
        .doc(this.infoModal.content.document_id)
        .update({
          [`${new_tracker_key}`]: firebase.firestore.FieldValue.arrayUnion({
            check_in_id: this.infoModal.content.check_in_id,
            check_in_type: this.infoModal.content.check_in_type,
            //"timestamp": this.infoModal.content.timestamp
          }),

          check_ins: firebase.firestore.FieldValue.arrayUnion({
            check_in_id: this.infoModal.content.check_in_id,
            is_impromptu: this.infoModal.content.is_impromptu,
            student_name: this.infoModal.content.student_name,
            student_id: this.infoModal.content.student_id,
            check_in_type: this.infoModal.content.check_in_type,
            check_in_length: this.infoModal.content.check_in_length,
            youth_development: this.infoModal.content.youth_development,
            check_in_date: this.infoModal.content.check_in_date, //this.form.checkinDate,
            timestamp: this.infoModal.content.timestamp,
            notes: this.infoModal.content.notes,
            //*** New tracker key to keep the monthly tracker in sync with changes
            tracker_key_ref: new_tracker_key,
            district: this.infoModal.content.district,
            otc: this.infoModal.content.otc,
          }),
        });

      let look = this.copiedModalContent.district;
      console.log(look);

      fb.otcStudents
        .doc(this.copiedModalContent.district)
        .collection("prod_otcs")
        .doc(this.copiedModalContent.otc)
        .collection("prod_caseload")
        .doc(this.copiedModalContent.document_id)
        .update({
          [`${tracker_key_ref}`]: firebase.firestore.FieldValue.arrayRemove({
            check_in_id: this.copiedModalContent.check_in_id,
            check_in_type: this.copiedModalContent.check_in_type,
            //"timestamp": this.copiedModalContent.timestamp
          }),

          check_ins: firebase.firestore.FieldValue.arrayRemove({
            check_in_id: this.copiedModalContent.check_in_id,
            is_impromptu: this.copiedModalContent.is_impromptu,
            student_name: this.copiedModalContent.student_name,
            student_id: this.copiedModalContent.student_id,
            check_in_type: this.copiedModalContent.check_in_type,
            check_in_length: this.copiedModalContent.check_in_length,
            youth_development: this.copiedModalContent.youth_development,
            check_in_date: this.copiedModalContent.check_in_date, //this.form.checkinDate,
            timestamp: this.copiedModalContent.timestamp,
            notes: this.copiedModalContent.notes,
            tracker_key_ref: this.copiedModalContent.tracker_key_ref,
            district: this.copiedModalContent.district,
            otc: this.copiedModalContent.otc,
          }),
        })
        .then(() => {
          //this.checkins_source(this.infoModal.content.document_id)

          let ot_status = this.$store.state.student_data.filter(
            (x) => x.document_id == this.infoModal.content.document_id
          )[0].on_track_status;

          let item = [
            {
              student_name: this.infoModal.content.student_name,
              document_id: this.infoModal.content.document_id,
              on_track_status: ot_status,
            },
          ];

          this.onRowSelected(item), this.hideUpdateModal(); // close modal

          console.log("Document updated"); // Document updated
        })
        .catch((error) => {
          console.error("Error updating doc", error);
        });
    },

    deleteCheckIn() {
      console.log("OK");

      //let month = 'september'

      //let param = month + '_' +'letter_home'
      //let k = 'check_ins'
      let tracker_key_ref = this.deleteModal.content.tracker_key_ref;
      //alert(tracker_key_ref)
      //db.collection('identites').where("partyMembers", "array-contains", {id: "7LNK....", name: "John Travolta"})
      //  fb.otcStudents.doc('merryville').collection('all_otcs').doc('dolbey').collection('caseload').doc(this.infoModal.content.document_id)
      //  .where(param, "array-contains", {check_in_id: })

      fb.otcStudents
        .doc(this.deleteModal.content.district)
        .collection("prod_otcs")
        .doc(this.deleteModal.content.otc)
        .collection("prod_caseload")
        .doc(this.deleteModal.content.document_id)
        .update({
          [`${tracker_key_ref}`]: firebase.firestore.FieldValue.arrayRemove({
            check_in_id: this.deleteModal.content.check_in_id,
            check_in_type: this.deleteModal.content.check_in_type,
          }),

          check_ins: firebase.firestore.FieldValue.arrayRemove({
            check_in_id: this.deleteModal.content.check_in_id,
            is_impromptu: this.deleteModal.content.is_impromptu,
            student_name: this.deleteModal.content.student_name,
            student_id: this.deleteModal.content.student_id,
            check_in_type: this.deleteModal.content.check_in_type,
            check_in_length: this.deleteModal.content.check_in_length,
            youth_development: this.deleteModal.content.youth_development,
            check_in_date: this.deleteModal.content.check_in_date, //this.form.checkinDate,
            timestamp: this.deleteModal.content.timestamp,
            notes: this.deleteModal.content.notes,
            tracker_key_ref: this.deleteModal.content.tracker_key_ref,
            district: this.deleteModal.content.district,
            otc: this.deleteModal.content.otc,
          }),
        })
        .then(() => {
          //this.checkins_source(this.infoModal.content.document_id)

          let ot_status = this.$store.state.student_data.filter(
            (x) => x.document_id == this.deleteModal.content.document_id
          )[0].on_track_status;

          let item = [
            {
              student_name: this.deleteModal.content.student_name,
              document_id: this.deleteModal.content.document_id,
              on_track_status: ot_status,
            },
          ];

          this.onRowSelected(item);
          this.hideDeleteModal();
          console.log("Document updated"); // Document updated
        })
        .catch((error) => {
          console.error("Error updating doc", error);
        });
    },
  },
};
</script>


<style>
#search-box {
  margin-top: 100px;
  margin-bottom: 20px;
  align-content: initial;
}

.left-col {
  border-right: 1px dashed #333;
}

#download-section {
  border-top: 1px dashed #333;
  margin-top: 30px;
  padding-top: 15px;
}

#profile-card {
  margin-top: -15px;
  margin-bottom: 15px;
}

.action {
  height: 29px !important;
  width: 55px !important;
  margin-top: 2px;
  padding-bottom: 2px !important;
}

#confirm-delete {
  margin-top: 0px;
}

.b-form-datepicker {
  margin-right: 4px;
}

.table {
  color: black !important;
}

label {
  text-align: left;
  font-weight: bold;
}

.col-form-label {
  text-align: left;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>