<template>
<div>
  <!-- <b-tabs content-class="mt-3" justified> -->

      <!-- <b-tab title="View ALL Check-ins by OTC" active> -->

  <b-container>
    <b-row class="my-5">
  
  <b-col sm="10" offset="1">

      <b-container fluid>
        <b-row>

            <!-- <b-col md="12" class="my-1">
                <b-form-group
                label="Filter on Notes/Type"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="sm"
                class="mb-0"
                >
                <b-input-group size="sm">
                    <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                </b-input-group>
                </b-form-group>
            </b-col> -->


          <!-- <b-col sm="5" md="5" class="my-1">
            <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="left"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col sm="5" offset="" md="5" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAllRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col> -->

        </b-row>

        <!-- Main table element -->
        <b-table
          :items="this.needsCheckin"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          stacked="md"
        thead-class="green-bg bg-primary text-white"
          small
          @filtered="onOtcFiltered"
        >
          <template #cell(name)="row">
            {{ row.value.first }} {{ row.value.last }}
          </template>

          <!-- <template #cell(actions)="row">
            <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1 action">
              Edit
            </b-button>
            <b-button class="mr-1 action" size="sm" @click="openDeleteModal(row.item, row.index, $event.target)">
              Delete
            </b-button>
          </template> -->

          <template #row-details="row">
            <b-card v-for="(i, key) in row.item" :key="key.id">
              <ul >
                <li v-for="(value, key) in i" :key="key">{{ key }}: {{ value }}</li>
                <!--<li v-for="note in row.item.all_notes" :key="note.notes">{{ key }}: {{ value }}</li>-->
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-container>
    </b-col>
    </b-row>
  </b-container>

  <!-- </b-tab> -->
  
  <!-- </b-tabs> -->
</div>
</template>

<script>
//import * as fb from '../firebase'
//import firebase from 'firebase/app';
//import JsonCSV from 'vue-json-csv'

  export default {

    components: { 
      //'download-csv': JsonCSV
    },
    data() {
      return {

        fields: [
          //{key: 'check_in_date', label: 'Date', sortable: true, sortDirection: 'desc', sort_priority: 0},
          //{key: 'otc', label: 'OTC', sortable: true, sortDirection: 'desc', sort_priority: 0},
          {key: 'studentName', label: 'Student', sortable: true, sortDirection: 'desc', sort_priority: 0},
          {key: 'onTrackStatus', label: 'On Track Status', sortable: true, sortDirection: 'desc', sort_priority: 0},
          {key: 'lastOneToOne', label: 'Last 1/1 Check-in', sortable: true, sortDirection: 'desc', sort_priority: 0},
          {key: 'lastOneToOneDelta', label: 'Days Since 1/1', sortable: true, sortDirection: 'desc', sort_priority: 0},
          {key: 'lastCheckinDate', label: 'Last Overall Check-in Date', sortable: true, sort_priority: 0},
          {key: 'lastCheckinType', label: 'Last Overall Check-in Type', sortable: true, sort_priority: 0},
          {key: 'lastOverallDelta', label: 'Days Since Last Overall Check-in', sortable: true, sort_priority: 0},
          //{key: 'notes', label: 'Notes', thStyle: { width: '300px'}},

        ],

        totalRows: 1,
        totalAllRows: this.listLength,//1,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: 'studentName',
        sortDesc: false,
        sortDirection: 'desc',
        side_filter: null,
        filterOn: [],
        alertsList: [],


        // text/keyword filter
        filter: '',

        modes: [
          //'multi', 
          'single', 
          //'range'
        ],
        // otcMenuFields: [
        //           {key: 'otc', hide: true, label: 'OTC', sortable: true, sortDirection: 'desc', sort_priority: 0},
        // ],
        selectMode: 'single',
        selected: [],
        students: []

      }
      
    },

    watch: {
      globalQuery() {
        // Force refreshing the data table.
        this.$root.$emit('bv::refresh::table', this.v)
      }
    },
    

    computed: {

      needsCheckin() {
        var data = this.$store.getters.lastCheckins

        let watchList = data.map(c=> ({

                              lastOneToOne: c.lastOneToOne.split(' ')[0],
                              onTrackStatus: c.onTrackStatus,
                              otc: c.otc,
                              studentName: c.studentName,
                              lastCheckinDate: c.lastOverall.last_check_in_date.split(', ')[0],
                              lastCheckinType: c.lastOverall.last_check_in_type,
                              lastOneToOneDelta: this.inDays(
                                                            new Date(), 
                                                            new Date(c.lastOneToOne)
                                                            ),
                              lastOverallDelta: this.inDays(
                                                            new Date(), 
                                                            //new Date(c.lastOverall.last_timestamp).toLocaleDateString('en-US', {timeZone: 'UTC'})
                                                            new Date(c.lastOverall.last_check_in_date)
                                                            )
                                                            

                              }))
        console.log(watchList.lastOverallDelta)
        console.log(watchList)

        // this.totalAllRows = watchList.length
        return watchList
        
      },

      listLength() {
          var data = this.$store.getters.lastCheckins
          return data.length
      }

    },
    
    methods: {

    //   onSubmit(event) {
    //     event.preventDefault()
    //     //alert(JSON.stringify(this.form))
    //   },

      onOtcRowSelected(items) {
        this.selected = items

        console.log(this.selected)
        //let data = this.viewAll
        let data = this.needsCheckin
        let otc = 'Garris'//this.selected[0].otc
        console.log(otc)
        console.log(data)

        // Filter by OTC
        let filtered = data.filter(x=> x.otc == otc )
        console.log(filtered)

        let watchList = filtered.map(c=> ({

                              lastOneToOne: c.lastOneToOne,
                              onTrackStatus: c.onTrackStatus,
                              otc: c.otc,
                              studentName: c.studentName,
                              lastCheckinDate: c.lastOverall.last_check_in_date,
                              lastCheckinType: c.lastOverall.last_check_in_type,
                              lastOneToOneDelta: this.inDays(new Date(), new Date(c.lastOneToOne)),
                              lastOverallDelta: this.inDays(new Date(), new Date(c.lastOverall.last_check_in_date))

                              }))
        console.log(watchList)

        this.totalAllRows = watchList.length

        this.alertsList = watchList

        return watchList

      },
      
      inDays: function(d1, d2) {
          console.log(d2)
        var t2 = d2.getTime();
        var t1 = d1.getTime();
 
        return Math.floor((t2-t1)/(24*3600*1000));
      },

      onFilteredStudents(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      // capitalizeFirstLetter(string) {
      //   return string.charAt(0).toUpperCase() + string.slice(1);
      // },

    //   onEdit(item) {
    //     if (item.isEditing)
    //       item.isEditing = false;
    //     else
    //       this.$set(item, 'isEditing', true)
    //       //alert(item)
    //   },

      onOtcFiltered() {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = this.needsCheckin.length
        this.currentPage = 1
      },



    },

}
</script>


<style>

#search-box {
  margin-top:100px;
  margin-bottom: 20px;
  align-content: initial;
}

table.text-center * {
  text-align: left;
}

.left-col {
  border-right: 1px dashed #333;
}

#profile-card {
  margin-top: -15px;
  margin-bottom: 15px;
}

.action {
  height: 29px !important;
  width: 55px !important;
  margin-top: 2px;
  padding-bottom: 2px !important;
}

#confirm-delete {
  margin-top: 0px;
}

.b-form-datepicker {
  margin-right:4px;
}

.table {
  color: black !important;
}

label {
  text-align:left;
  font-weight: bold;
}

.col-form-label {
  text-align:left
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>